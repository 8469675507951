import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Font } from '@components/atoms';
import ListContent from '@components/organisms/telepharmacyTable/components/listContent';
import {
  convertPaymentStatusToKoreanAtCompleteTreatment,
  convertSubstitutedPatientStatus,
  convertSubstitutedPharmacyStatus,
} from '@components/organisms/utils/domain';
import { commonCode, styleVariables } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import moment from 'moment';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import { StatusTag } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;
const { BREAK_POINTS } = styleVariables;

function ListItem({
  item,
}: {
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { timeFormatter, phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문수령';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  // 탈퇴 유저 이름에 붙는 '(탈퇴(Number))' 형식을 '앱 탈퇴 고객'으로 바꾸기 위한 예외처리
  let patientName = item.patient?.name || '';
  if (patientName.includes('(탈퇴')) {
    const newText = patientName.split('(');
    for (let i = 1; i < newText.length; i += 1) {
      const replaceText = newText[i].split(')')[0];
      patientName = patientName.replace(replaceText, '앱 탈퇴 고객');
    }
  }

  const isSubstitutedTextColorRed = item.is_substituted_patient === false;

  return (
    <Style.ListItem
      to={`/home/completeTreatment/completeTreatmentDetail?id=${item.id}`}
    >
      <ListContent.Element flex={LISTITEM_FLEX[0].flex}>
        <Style.ListItemLayout>
          <StatusTag
            tpStatus={item.status}
            deliveryStatus={item.delivery_status}
            paymentStatus={item.payment_status}
          />
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[1].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2" color="fill/dark">
            {deliveryType}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[2].flex}>
        <Style.SubstitutedArea>
          <Font
            fontType="body2"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/dark'
            }
          >
            {`환자 : ${convertSubstitutedPatientStatus(
              item.is_substituted_patient,
            )}`}
          </Font>
          <Font
            fontType="body2"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/dark'
            }
          >
            {`약사 : ${
              item.auto_confirmed
                ? convertSubstitutedPatientStatus(item.is_substituted_patient)
                : convertSubstitutedPharmacyStatus(item.is_substituted_pharmacy)
            }`}
          </Font>
        </Style.SubstitutedArea>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[3].flex}>
        <Style.ListItemColumnLayout>
          <Font fontType="body2_medium" color="fill/black">
            {patientName}
          </Font>
          <Font fontType="body3" color="fill/medium">
            {item.telemedicine && item.telemedicine.telemedicine_hash}
          </Font>
        </Style.ListItemColumnLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[4].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2" color="fill/dark">
            {phoneFormatter.prettyPhoneNumberFormatter(
              item.patient?.phone || item.requester?.phone,
            )}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[5].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2" color="fill/dark">
            {`${priceFormatter.commaFormatter(
              item.amount ? String(item.amount) : '0',
            )}원`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      {Number(windowWidth) >= BREAK_POINTS.size1024 && (
        <ListContent.Element flex={LISTITEM_FLEX[6].flex}>
          <Style.ListItemColumnLayout>
            <Font fontType="body2_medium">
              {convertPaymentStatusToKoreanAtCompleteTreatment(
                item.payment_status,
              )}
            </Font>
            <Font fontType="body3" color="fill/medium">
              {item.payment_date
                ? moment(item.payment_date).format('YYYY-M-DD HH:mm')
                : ''}
            </Font>
          </Style.ListItemColumnLayout>
        </ListContent.Element>
      )}
    </Style.ListItem>
  );
}

export default ListItem;
