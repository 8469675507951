import { QueryClient } from '@tanstack/react-query';

export const QUERY_KEY = {
  TP_PROGRESS_ITEM: 'TP_PROGRESS_ITEM',
  TP_PRESCRIPTION: 'TP_PRESCRIPTION',
  TP_PRESCRIPTION_COST: 'TP_PRESCRIPTION_COST',
  TP_SUBSTITUTE_PRESCRIPTION: 'TP_SUBSTITUTE_PRESCRIPTION',
  TP_PRESCRIPTION_QR_CODE_IMAGE: 'TP_PRESCRIPTION_QR_CODE_IMAGE',

  TP_PHARMACY_INFO: 'TP_PHARMACY_INFO',

  TP_PROCESS_COUNT: 'TP_PROCESS_COUNT',
  TP_PROCESS_DETAIL: 'TP_PROCESS_DETAIL',
  TP_PROCESS_LIST: 'TP_PROCESS_LIST',

  PH_OFF_SCHEDULE: 'PH_OFF_SCHEDULE',

  AD_IMAGES: 'AD_IMAGES',
} as const;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
