import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import BNoticeModal, {
  INotice,
} from '@components/meraki-ui/BModal/BNoticeModal';
import BPersonalInfoModal from '@components/meraki-ui/BModal/BPersonalInfoModal';
import { NoData, UpdateNudge } from '@components/molecules';
import {
  AcceptedTelepharmacyTable,
  CompletedTelepharmacyTable,
  ConfirmedTelepharmacyTable,
  OngoingParcelTelepharmacyTable,
  OngoingQuickTelepharmacyTable,
  TelepharmacyDetailInformation,
} from '@components/organisms';
import {
  getManagementMedicineListCreatedAfterDate,
  isWegovyRegister,
} from '@components/organisms/managementMedicineTableV2';
import PickupTelepharmacyTable from '@components/organisms/pickupTelepharmacyTable';
import NotificationToast from '@components/organisms/toast/notificationToast';
import { TOAST_ID } from '@components/organisms/utils/config';
import { firebase as firebaseConfig } from '@configs';
import { firebase, tokenList } from '@constants';
import { queryClient } from '@constants/react-query';
import { useNotice } from '@hooks/featureHooks/useNotice';
import { usePersonalInfo } from '@hooks/featureHooks/usePersonalInfo';
import CompleteTreatmentPage from '@pages/completeTreatmentPage';
import ConfirmedTreatmentPage from '@pages/confirmedTreatmentPage';
import GlobalNavBar from '@pages/globalNavBar';
import LinkGatePage from '@pages/linkGatePage';
import LoginPage from '@pages/loginPage';
import MedicineManagementPage from '@pages/medicienManagementPage';
import NewReservationPage from '@pages/newReservationPage';
import TermsOfService from '@pages/termsOfService';
import TimeManagementPage from '@pages/timeManagementPage';
import Wrapper from '@pages/wrapper';
import useMedicineSoldOutAlertModalHook from '@pages/wrapper/modals/medicineSoldOutAlertModal/hooks';
import { myDoctorAPI } from '@services/myDoctor';
import {
  GetManagedMedicineItemType,
  ManagedMedicineListDataType,
} from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_setFirstLoginModal } from '@stores/modalStore/setFirstLoginModal';
import {
  getManagementMedicineList,
  getPharmacyAutoConfirmed,
  selectMedicineManagementList,
  selectPharmacyAutoConfirmed,
  selectTelepharmacyCount_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import {
  impersonalLogin,
  RegistrationFCMToken,
  selectUserInformationIsLogin,
  selectUserInformationPharmacistData,
} from '@stores/userInformationStore';
import {
  selectIsVersionUp,
  selectVersion,
  set_versionInfo,
} from '@stores/versionStore';
import { QueryClientProvider, useMutation } from '@tanstack/react-query';
import { fetchAPI, storageHandler } from '@utils';
import { getNotificationPermissionStatus } from '@utils/browser';
import { decryptData } from '@utils/impersonal';
import {
  getLocalStorageState,
  getSessionStorageState,
  removeAllLocalStorageState,
  setLocalStorageState,
} from '@utils/storageHandler';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notificationNumberChangeImage } from './assets';
import MobileRouter from './mobile/router';
import VersionData from './version/version.json';

const { FIREBASE_CONSTANT } = firebase;
const { REST_AUTH_TOKEN } = tokenList;
const { setSessionStorageState } = storageHandler;

// fcm -> 브라우저가 서비스 워커를 지원할 때만 로직이 실행되도록 (카카오 인앱 브라우저 때문)
if (
  typeof window !== 'undefined' &&
  typeof window.navigator.serviceWorker !== 'undefined'
) {
  const app = initializeApp(firebaseConfig.firebaseConfig);
  const messaging = getMessaging(app);
  getToken(messaging, {
    vapidKey: FIREBASE_CONSTANT.vapidKey,
  })
    .then((currentToken) => {
      if (currentToken) {
        setSessionStorageState('FCMToken', currentToken);
      } else {
        console.error(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });

  onMessage(messaging, (payload: any) => {
    const showNotification = (
      notificationTitle: any,
      notificationOptions: any,
      notificationLink: any,
    ) => {
      const notification = new Notification(
        notificationTitle,
        notificationOptions,
      );

      notification.onshow = () => {
        mixpanel.track('[CRM] Receive Push Notification', {
          Title: notificationTitle,
        });
      };

      notification.onclick = () => {
        window.location.href = notificationLink;
        mixpanel.track('[CRM] Open Push Notification', {
          Title: notificationTitle,
        });
      };
    };
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: 'https://d7qkbi83dtokl.cloudfront.net/prod/web/common/fcm_logo.png',
    };
    const notificationLink = payload.data.link;

    if (Notification.permission === 'granted') {
      showNotification(
        notificationTitle,
        notificationOptions,
        notificationLink,
      );
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          showNotification(
            notificationTitle,
            notificationOptions,
            notificationLink,
          );
        }
      });
    }
  });
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* ALLOW globalStyle에서 스타일 강제로 변경함 */}
      <ToastContainer
        position="top-right"
        autoClose={1200}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        theme="colored"
        transition={Bounce}
        closeButton={false}
      />

      <Routes>
        <Route path="/mobile/*" element={<MobileRouter />} />

        <Route
          path="/linkGate"
          element={
            <Wrapper>
              <LinkGatePage />
            </Wrapper>
          }
        />
        <Route
          path="/*"
          element={
            <Wrapper>
              <MainRouter />
            </Wrapper>
          }
        />
      </Routes>
    </QueryClientProvider>
  );
}

function MainRouter() {
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector(selectUserInformationIsLogin);
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const isPharmacyAutoConfirmed = useAppSelector(selectPharmacyAutoConfirmed);
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const navigate = useNavigate();
  const location = useLocation();

  // 로그인 감지기
  useEffect(() => {
    const accessToken = getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN);
    const searchParams = new URLSearchParams(location.search);
    const impersonal = searchParams.get('impersonal') === 'true';
    const encrypted_data = searchParams.get('encrypted_data');

    (async () => {
      if (impersonal && encrypted_data) {
        removeAllLocalStorageState();

        try {
          const decryptedData = await decryptData(encrypted_data);
          const queryString = decryptedData.substring(
            decryptedData.indexOf('access_token='),
          );
          const decryptedParams = new URLSearchParams(queryString);

          const impersonalAccessToken = decryptedParams.get('access_token');
          const impersonalActor = decryptedParams.get('actor');

          if (impersonalAccessToken && impersonalActor) {
            await dispatch(
              impersonalLogin({
                accessToken: impersonalAccessToken,
                actor: impersonalActor,
              }),
            );
            await dispatch(RegistrationFCMToken());
            navigate('/home', { replace: true });
            return;
          }
        } catch (error) {
          console.error('데이터 복호화 또는 파싱 오류:', error);
        }
      }
    })();

    // 의사/약사/간호사 로그인 시
    if (!isLogin && accessToken && !impersonal) {
      dispatch(RegistrationFCMToken());
    } else if (
      !isLogin &&
      !getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN)
    ) {
      navigate('/login', { replace: true });
    }
  }, [dispatch, isLogin, navigate, location.search]);

  const [managedMedicineItem, setManagedMedicineItem] = useState<
    GetManagedMedicineItemType[] | null
  >(null);

  useEffect(() => {
    if (!isLogin || !pharmacist.pharmacy_id) return;

    const permissionStatus = getNotificationPermissionStatus();

    Promise.all([
      dispatch(
        getPharmacyAutoConfirmed({
          id: pharmacist.pharmacy_id || 0,
        }),
      ),
      dispatch(
        getManagementMedicineList({
          id: pharmacist.pharmacy_id || 0,
          offset: 0,
          limit: 300,
        }),
      ),
    ]).then((values) => {
      const managementMedicineListResult = values[1]
        .payload as ManagedMedicineListDataType;
      if (
        managementMedicineListResult &&
        managementMedicineListResult.results
      ) {
        const filteredManagementMedicineListResult =
          managementMedicineListResult.results.filter((managementMedicine) =>
            getManagementMedicineListCreatedAfterDate(managementMedicine),
          );
        setManagedMedicineItem(filteredManagementMedicineListResult);
      }
    });

    mixpanel.register({
      'Alarm Permission': permissionStatus,
    });
  }, [dispatch, isLogin, pharmacist.pharmacy_id]);

  // 우측 넛지 토스트
  useEffect(() => {
    const permissionStatus = getNotificationPermissionStatus();

    const isShowPrepareMedicineProbabilityUp = localStorage.getItem(
      TOAST_ID.NOTIFICATION_PREPARE_MEDICINE_PROBABILITY_UP,
    );

    if (!isLogin || !pharmacist.pharmacy_id) return;

    if (
      permissionStatus !== 'granted - 사용자가 알림 표시를 수락합니다.' &&
      isLogin
    ) {
      toast(<NotificationToast showNotificationToastType="notification" />, {
        autoClose: false,
        toastId: TOAST_ID.NOTIFICATION,
      });
    } else if (
      isLogin &&
      managedMedicineItem &&
      managementMedicineList &&
      managementMedicineList.filter((managementMedicine) =>
        getManagementMedicineListCreatedAfterDate(managementMedicine),
      ).length === 0
    ) {
      toast(<NotificationToast showNotificationToastType="price-register" />, {
        autoClose: false,
        toastId: TOAST_ID.NOTIFICATION,
      });
    } else if (
      isLogin &&
      managedMedicineItem &&
      managementMedicineList &&
      !isWegovyRegister(managementMedicineList)
    ) {
      toast(<NotificationToast showNotificationToastType="wegovy-register" />, {
        autoClose: false,
        toastId: TOAST_ID.NOTIFICATION,
      });
    } else if (
      isLogin &&
      managedMedicineItem &&
      !isPharmacyAutoConfirmed.auto_confirmed
    ) {
      toast(<NotificationToast showNotificationToastType="auto-confirm" />, {
        autoClose: false,
        toastId: TOAST_ID.NOTIFICATION,
      });
    } else if (
      isLogin &&
      managedMedicineItem &&
      isPharmacyAutoConfirmed.auto_confirmed &&
      isShowPrepareMedicineProbabilityUp !== 'isRead'
    ) {
      toast(
        <NotificationToast showNotificationToastType="prepareMedicine-probability-up" />,
        {
          autoClose: false,
          toastId: TOAST_ID.NOTIFICATION_PREPARE_MEDICINE_PROBABILITY_UP,
        },
      );
    }
  }, [
    dispatch,
    isLogin,
    pharmacist.pharmacy_id,
    isPharmacyAutoConfirmed,
    managedMedicineItem,
    managementMedicineList,
  ]);

  return (
    <Routes>
      <Route path="/*" element={<LoginRouter />} />
      <Route path="/termsOfService" element={<TermsOfService />} />
      <Route path="/login/*" element={<LoginRouter />} />
      <Route path="/home/*" element={<HomeRouter />} />
      <Route path="/linkGate" element={<LinkGatePage />} />
    </Routes>
  );
}

function LoginRouter() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
    </Routes>
  );
}

function HomeRouter() {
  const {
    personalInfoReport,
    personInfoReportModalVisible,
    openPersonInfoReportModal,
    closePersonInfoReportModal,
    onAgreePersonalInfoReport,
    isLoading,
    onDetail,
  } = usePersonalInfo();

  const dispatch = useAppDispatch();
  const pharmacy = useAppSelector(selectUserInformationPharmacistData);
  const isLogin = useAppSelector(selectUserInformationIsLogin);
  const isVersionUp = useAppSelector(selectIsVersionUp);
  const newVersion = useAppSelector(selectVersion);
  const { pathname } = useLocation();

  useEffect(() => {
    const hasJustResetPassword = getSessionStorageState('email');
    if (hasJustResetPassword && hasJustResetPassword.length > 0) {
      dispatch(openModal_setFirstLoginModal());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!personalInfoReport?.data) {
      openPersonInfoReportModal();
    }
  }, [personalInfoReport?.data, openPersonInfoReportModal]);

  const { mutateAsync } = useMutation({
    mutationFn: () => myDoctorAPI.getConfig({ key: 'ph-admin-version' }),
  });

  // <--- 위고비 임시 대응 --->
  const { mutateAsync: getWegovyActive } = useMutation({
    mutationFn: () =>
      fetchAPI.get(
        `pharmacist-api/telepharmacy/wegovy-active/`,
        { pharmacy_id: pharmacy.pharmacy_id },
        'myDoctor',
      ),
  });

  const {
    openModal: openMedicineSoldOutAlertModal,
    setMedicineSoldOutAlertTargetMedicine,
  } = useMedicineSoldOutAlertModalHook();

  useEffect(() => {
    if (pharmacy.pharmacy_id) {
      getWegovyActive().then((response) => {
        if (response.data.cache_value) {
          setMedicineSoldOutAlertTargetMedicine('auto-reject');
          openMedicineSoldOutAlertModal();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pharmacy.pharmacy_id, pathname]);
  // <--- END 위고비 임시 대응 --->

  useEffect(() => {
    const clientVersionList = VersionData.phAdminVersioning;
    const clientVersion = clientVersionList[0]?.version || '1.0.0';

    const fetchVersionInfo = async () => {
      try {
        if (isLogin) {
          const versionInfo = await mutateAsync();
          const versionValue = versionInfo.data?.value;
          const version = JSON.parse(versionValue) as { version: string };

          if (version?.version && clientVersion) {
            dispatch(
              set_versionInfo({
                isVersionUp: version.version !== clientVersion,
                version: version.version,
              }),
            );
          }
        }
      } catch (error) {
        console.error('버전 정보를 가져오는 중 오류 발생:', error);
      }
    };

    if (isLogin) {
      fetchVersionInfo();
    }
  }, [dispatch, isLogin, mutateAsync, newVersion, pathname]);

  useEffect(() => {
    if (pharmacy && pharmacy.pharmacy_id && pharmacy.pharmacy_name) {
      ChannelService.loadScript();

      ChannelService.boot({
        pluginKey: '175bf8ac-ec56-4b2e-8839-850301f472af',
        memberId: String(pharmacy?.pharmacy_id),
        profile: {
          name: pharmacy?.pharmacy_name || '',

          tags: ['pharmacist'],
        },
      });
    }
  }, [pharmacy]);

  return (
    <div className="App">
      {isVersionUp && <UpdateNudge />}
      <GlobalNavBar>
        <ConfirmedTreatmentPage>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/home/newReservation" replace />}
            />
            <Route
              path="/newReservation/*"
              element={<NewReservationRouter />}
            />
            <Route
              path="/confirmedTreatment/*"
              element={<ConfirmedTreatmentRouter />}
            />
            <Route
              path="/completeTreatment/*"
              element={<CompleteTreatmentRouter />}
            />
            <Route
              path="/medicineManagement/*"
              element={<MedicineManagementPage />}
            />
            <Route path="/timeManagement" element={<TimeManagementPage />} />
            <Route
              path="/waitForDispense/*"
              element={<ConfirmedTreatmentConfirmedRouter />}
            />
          </Routes>
        </ConfirmedTreatmentPage>
      </GlobalNavBar>
      {personalInfoReport?.data?.agree === false ||
      personalInfoReport?.data?.agree === null ? (
        <BPersonalInfoModal
          isLoading={isLoading}
          open={personInfoReportModalVisible}
          onAgree={onAgreePersonalInfoReport}
          onDetail={onDetail}
          onOpenChange={closePersonInfoReportModal}
        />
      ) : undefined}

      <a
        style={{
          position: 'fixed',
          bottom: 24,
          right: 88,
          zIndex: 999,
          width: 283,
          height: 56,
          cursor: 'pointer',
        }}
        href="https://form.typeform.com/to/Fv68eeaN"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={notificationNumberChangeImage}
          width={283}
          height={56}
          alt="알림 수신번호 추가,변경 버튼"
        />
      </a>
    </div>
  );
}

function NoticeModal({ notice }: { notice: INotice }) {
  const [modalVisible, modalControl] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    modalControl(false);
  }, []);

  const onCancel = useCallback(() => {
    if (window && notice) {
      setLocalStorageState(`pharmacist_admin_notice_${notice.id}`, 'isRead');
      closeModal();
    }
  }, [closeModal, notice]);

  useEffect(() => {
    if (window && notice) {
      const isRead = getLocalStorageState(
        `pharmacist_admin_notice_${notice.id}`,
      );

      if (!isRead) {
        modalControl(true);
      }
    }
  }, [notice]);

  return (
    <BNoticeModal
      open={modalVisible}
      onOpenChange={closeModal}
      onCancel={onCancel}
      notice={notice}
    />
  );
}

function NewReservationRouter() {
  const requestTelepharmacyCount = useAppSelector(
    selectTelepharmacyCount_requested,
  );

  const { noticeList } = useNotice();

  return (
    <NewReservationPage>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/home/newReservation/requested" replace />}
        />
        <Route
          path="/requested"
          element={
            requestTelepharmacyCount && requestTelepharmacyCount > 0 ? (
              <TelepharmacyDetailInformation pageType="requested" />
            ) : (
              <NoData title="접수대기 중인 요청이 없습니다." />
            )
          }
        />
        <Route path="/accepted/*" element={<NewReservationAcceptedRouter />} />
      </Routes>
      {noticeList
        ? noticeList.map((notice) => {
            return <NoticeModal key={notice.id} notice={notice} />;
          })
        : null}
    </NewReservationPage>
  );
}

function NewReservationAcceptedRouter() {
  return (
    <Routes>
      <Route path="/" element={<AcceptedTelepharmacyTable />} />
      <Route
        path="/newReservationAcceptedDetail"
        element={<TelepharmacyDetailInformation pageType="accepted" />}
      />
    </Routes>
  );
}

function ConfirmedTreatmentRouter() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/home/confirmedTreatment/pick-up" replace />}
      />
      <Route
        path="/confirmed/*"
        element={<ConfirmedTreatmentConfirmedRouter />}
      />
      <Route
        path="/ongoing-quick/*"
        element={<ConfirmedTreatmentOngoingQuickRouter />}
      />
      <Route
        path="/ongoing-parcel/*"
        element={<ConfirmedTreatmentOngoingParcelRouter />}
      />
      <Route path="/pick-up/*" element={<ConfirmedTreatmentPickupRouter />} />
    </Routes>
  );
}

function ConfirmedTreatmentConfirmedRouter() {
  return (
    <Routes>
      <Route path="/" element={<ConfirmedTelepharmacyTable />} />
      <Route
        path="/confirmedDetail"
        element={<TelepharmacyDetailInformation pageType="confirmed" />}
      />
    </Routes>
  );
}

function ConfirmedTreatmentOngoingQuickRouter() {
  return (
    <Routes>
      <Route path="/" element={<OngoingQuickTelepharmacyTable />} />
      <Route
        path="/confirmedTreatmentOngoingQuickDetail"
        element={<TelepharmacyDetailInformation pageType="ongoing_quick" />}
      />
    </Routes>
  );
}

function ConfirmedTreatmentPickupRouter() {
  return (
    <Routes>
      <Route path="/" element={<PickupTelepharmacyTable />} />
      <Route
        path="/confirmedPickupDetail"
        element={<TelepharmacyDetailInformation pageType="pick_up" />}
      />
    </Routes>
  );
}

function ConfirmedTreatmentOngoingParcelRouter() {
  return (
    <Routes>
      <Route path="/" element={<OngoingParcelTelepharmacyTable />} />
      <Route
        path="/confirmedTreatmentOngoingParcelDetail"
        element={<TelepharmacyDetailInformation pageType="ongoing_parcel" />}
      />
    </Routes>
  );
}

function CompleteTreatmentRouter() {
  return (
    <CompleteTreatmentPage>
      <Routes>
        <Route path="/" element={<CompletedTelepharmacyTable />} />
        <Route
          path="/completeTreatmentDetail"
          element={<TelepharmacyDetailInformation pageType="completed" />}
        />
      </Routes>
    </CompleteTreatmentPage>
  );
}

export default App;
