import { SpaceType } from '@components/meraki-ui/BTheme';
import { BREAK_POINTS } from '@constants/styleVariables';
import { useInterval } from '@hooks/commonHooks';
import { LNBAdImage } from '@pages/newReservationPage/LNBCarousel';
import { motion } from 'motion/react';
import { memo, useCallback, useState } from 'react';
import styled from 'styled-components';

const CarouselImage = memo(
  ({ imageUrl, linkUrl }: { imageUrl: string; linkUrl: string }) => {
    return (
      <div
        onClick={() => {
          if (linkUrl) {
            window.open(linkUrl, '_blank');
          }
        }}
      >
        <img
          width="100%"
          height="100%"
          src={imageUrl}
          alt="굉고 이미지"
          loading="eager"
          style={{
            willChange: 'transform',
            backfaceVisibility: 'hidden',
          }}
        />
      </div>
    );
  },
);

interface ImageSliderProps {
  width: string;
  height: string;
  borderRadius: SpaceType;
  imageList: LNBAdImage[];
  interval?: number;
}

function ImageSlider({
  width,
  height,
  borderRadius,
  imageList,
  interval,
}: ImageSliderProps) {
  const [page, setPage] = useState(0);

  const paginate = useCallback(
    (newDirection: number) => {
      setPage(page + newDirection);
    },
    [page],
  );

  useInterval(() => {
    if (page === (imageList?.length || 0) - 1) {
      paginate(-1);
    } else {
      paginate(1);
    }
  }, interval);

  return (
    <CustomFlex borderRadius={borderRadius}>
      <CarouselWrapper width={width} height={height}>
        {imageList.map(({ imageUrl, linkUrl }, idx) => (
          <motion.div
            key={idx}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}
            animate={{
              opacity: page === idx ? 1 : 0.3,
              zIndex: page === idx ? 2 : 1,
            }}
            transition={{
              opacity: {
                duration: 0.6,
                ease: 'linear',
              },
              zIndex: { duration: 0 },
            }}
          >
            <CarouselImage imageUrl={imageUrl} linkUrl={linkUrl} />
          </motion.div>
        ))}
      </CarouselWrapper>
    </CustomFlex>
  );
}

const CarouselWrapper = styled.div<{ width: string; height: string }>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow: hidden;
  cursor: pointer;
`;

const CustomFlex = styled('div')<{ borderRadius: number }>`
  display: flex;
  border-radius: ${(props) => props.borderRadius}px;
  overflow: hidden;
  @media (max-width: ${BREAK_POINTS.size720}px) {
    display: none;
  }
`;

export default ImageSlider;
