import ImageSlider from '@components/atoms/ImageSlider';
import { ADMIN_CONFIG } from '@constants/adminConfig';
import { QUERY_KEY } from '@constants/react-query';
import { myDoctorAPI } from '@services/myDoctor';
import { useQuery } from '@tanstack/react-query';

export interface LNBAdImage {
  imageUrl: string;
  linkUrl: string;
}

function LNBCarousel() {
  const { data: adImages } = useQuery({
    queryKey: [QUERY_KEY.AD_IMAGES, ADMIN_CONFIG.PHARMACIST_PARTNER_PAGE_AD],
    queryFn: () =>
      myDoctorAPI.getConfig({ key: ADMIN_CONFIG.PHARMACIST_PARTNER_PAGE_AD }),
    select: (response) => {
      return JSON.parse(response.data.value).LNB as LNBAdImage[];
    },
  });

  if (!adImages) {
    return null;
  }

  return (
    <ImageSlider
      width="272px"
      height="272px"
      borderRadius={8}
      imageList={adImages}
      interval={6000}
    />
  );
}

export default LNBCarousel;
