import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Font } from '@components/atoms';
import { COMMON_CODE } from '@constants/commonCode';
import {
  phoneFormatter,
  priceFormatter,
  timeFormatter,
} from '@functions/formatters';
import { isNil } from 'lodash';
import moment from 'moment';
import { TelepharmacyItemType } from 'types/telepharmacyType';
import { v4 as uuidv4 } from 'uuid';
import { PageStatus } from '../telepharmacyDetailInformation';
import { IReservationInformationItem } from '../telepharmacyDetailInformation/sections/reservationInformationPartItem/ReservationInformationItem';

export const convertSubstitutedPatientStatus = (
  value: boolean | null | undefined,
) => {
  if (value === true) {
    return '대체 O';
  }
  if (value === false) {
    return '대체 X';
  }

  return '-';
};

export const convertSubstitutedPatientKoreanStatus = (
  value: boolean | null | undefined,
) => {
  if (value === true) {
    return '동의';
  }
  if (value === false) {
    return '거부';
  }
  return '-';
};

export const convertSubstitutedPharmacyStatus = (
  value: boolean | null | undefined,
) => {
  if (value === true) {
    return '대체 O';
  }
  if (value === false) {
    return '대체 X';
  }
  return '-';
};

export const convertSubstitutedPharmacyKoreanStatus = (
  value: boolean | null | undefined,
) => {
  if (value === true) {
    return '동일성분 대체조제';
  }
  if (value === false) {
    return '처방전대로 조제';
  }
  return '-';
};

export const convertPaymentStatusToKorean = (
  paymentStatus: string | undefined,
) => {
  switch (paymentStatus) {
    case COMMON_CODE.PAYMENT_STATUS.FINISHED:
      return '결제완료';
    case COMMON_CODE.PAYMENT_STATUS.FAILED:
      return '결제실패';
    case COMMON_CODE.PAYMENT_STATUS.REQUESTED:
      return '결제요청';
    case COMMON_CODE.PAYMENT_STATUS.DEFAULT:
      return '결제대기';
    case COMMON_CODE.PAYMENT_STATUS.CANCELED:
      return '결제취소';
    default:
      return '-';
  }
};

export const convertPaymentStatusToKoreanAtCompleteTreatment = (
  paymentStatus: string | undefined,
) => {
  switch (paymentStatus) {
    case COMMON_CODE.PAYMENT_STATUS.FINISHED:
      return '결제완료';
    case COMMON_CODE.PAYMENT_STATUS.FAILED:
      return '결제실패';
    case COMMON_CODE.PAYMENT_STATUS.REQUESTED:
      return '결제요청';
    case COMMON_CODE.PAYMENT_STATUS.CANCELED:
      return '결제취소';
    case COMMON_CODE.PAYMENT_STATUS.DEFAULT:
      return '-';
    default:
      return '-';
  }
};

export const getDeliveryMethod = ({
  item,
}: {
  item: TelepharmacyItemType | undefined;
}) => {
  let deliveryType: string;
  let iconURL: any;

  switch (item?.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  return {
    deliveryType,
    iconURL,
  };
};

export const getProofUserType = ({ item }: { item: TelepharmacyItemType }) => {
  const proofUserType = item.tm?.telemedicine_proof?.proof_user_type;

  const proofUserTypeText =
    proofUserType === 'remote_resident_user'
      ? '섬•벽지 지역 거주자'
      : proofUserType === 'disabled_person_user'
      ? '장애인'
      : proofUserType === 'long_term_care_need_user'
      ? '장기요양등급 대상자'
      : '약 배송 불가 대상';

  return proofUserTypeText;
};

export const getPatientName = ({
  item,
}: {
  item: TelepharmacyItemType | undefined;
}) => {
  // 탈퇴 유저 이름에 붙는 '(탈퇴(Number))' 형식을 '앱 탈퇴 고객'으로 바꾸기 위한 예외처리
  let patientName = item?.tm?.patient?.name || '';
  if (patientName.includes('(탈퇴')) {
    const newText = patientName.split('(');
    for (let i = 1; i < newText.length; i += 1) {
      const replaceText = newText[i].split(')')[0];
      patientName = patientName.replace(replaceText, '앱 탈퇴 고객');
    }
  }

  return patientName;
};

const getReservationTime = ({ item }: { item: TelepharmacyItemType }) => {
  let reservationTime;
  const targetTime = item.requested_time;
  const requestedTimeDate = targetTime
    ? moment(targetTime).format('YYYY-MM-DD')
    : undefined;
  const requestedTimeDateText =
    timeFormatter.dateTextFormatter(requestedTimeDate);

  if (targetTime) {
    reservationTime = `${requestedTimeDateText} ${moment(targetTime).format(
      'HH:mm',
    )}`;
  } else {
    reservationTime = `${requestedTimeDateText} 가능한 빨리`;
  }

  return reservationTime;
};

const formatPickupMethodInfo = (deliveryType: string) => {
  return {
    id: uuidv4(),
    title: '약 수령 방법',
    text: deliveryType === '방문' ? '방문수령' : deliveryType,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {deliveryType === '방문' ? '방문수령' : deliveryType}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatIsDeliveryAvailableInfo = (proofUserTypeText: string) => {
  return {
    id: uuidv4(),
    title: '약 배송 가능 여부',
    text: proofUserTypeText,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {proofUserTypeText}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatPatientNameInfo = (patientName: string) => {
  return {
    id: uuidv4(),
    title: '환자명',
    text: patientName,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {patientName}
        </Font>
      );
    },
    iconURL: null,
    canCopy: true,
  };
};

const formatPhoneNumberInfo = (phoneNumber: string | undefined) => {
  return {
    id: uuidv4(),
    title: '휴대폰번호',
    text: phoneNumber,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {phoneNumber || '-'}
        </Font>
      );
    },
    iconURL: null,
    canCopy: true,
  };
};

const formatReservationTimeInfo = (reservationTime: string) => {
  return {
    id: uuidv4(),
    title: '조제요청일시',
    text: reservationTime,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {reservationTime}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatSymptomInfo = (symptom: string | undefined) => {
  return {
    id: uuidv4(),
    title: '증상',
    text: symptom,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {symptom}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatReservationNumberInfo = (reservationNumber: string | undefined) => {
  return {
    id: uuidv4(),
    title: '예약번호',
    text: reservationNumber,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {reservationNumber}
        </Font>
      );
    },
    iconURL: null,
    canCopy: true,
  };
};

const formatPendingPaymentAmountInfo = (
  amount: string,
  paymentException?: string,
) => {
  return {
    id: uuidv4(),
    title: '결제 예정 금액',
    text: amount,
    content: () => {
      return (
        <Font
          fontType="body1_medium"
          color={paymentException ? 'state/distructive' : 'fill/accent'}
        >
          {amount}
          {paymentException && ` (${paymentException})`}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatPaymentAmountInfo = (amount: string, paymentException?: string) => {
  return {
    id: uuidv4(),
    title: '결제 금액',
    text: amount,
    content: () => {
      return (
        <Font
          fontType="body1_medium"
          color={paymentException ? 'state/distructive' : 'fill/accent'}
        >
          {amount}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatPaymentStatusInfo = (
  paymentStatus: string | undefined,
  paymentException?: string,
) => {
  return {
    id: uuidv4(),
    title: '결제상태',
    text: paymentStatus,
    content: () => {
      return (
        <Font
          fontType="body1_medium"
          color={paymentException ? 'state/distructive' : 'fill/dark'}
        >
          {convertPaymentStatusToKorean(paymentStatus)}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const convertPaymentTypeToKorean = (paymentType: string) => {
  switch (paymentType) {
    case 'tosspay':
      return '토스페이';
    case 'kakaopay':
      return '카카오페이';
    default:
      return '카드결제';
  }
};

const formatPaymentWayInfo = (paymentType: string | undefined) => {
  return {
    id: uuidv4(),
    title: '결제종류',
    text: paymentType,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {paymentType ? convertPaymentTypeToKorean(paymentType) : '-'}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatPaymentDoneTimeInfo = (
  paymentDoneTime: string | undefined | null,
) => {
  return {
    id: uuidv4(),
    title: '결제완료 일시',
    text: paymentDoneTime,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {paymentDoneTime
            ? moment(paymentDoneTime).format('YYYY-MM-DD HH:mm')
            : '-'}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatPaymentCancelTimeInfo = (paymentCancelTime: string) => {
  return {
    id: uuidv4(),
    title: '결제취소 일시',
    text: paymentCancelTime,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {paymentCancelTime}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatAddressStringInfo = (address: string | undefined) => {
  return {
    id: uuidv4(),
    title: '주소',
    text: address,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {address || '-'}
        </Font>
      );
    },
    iconURL: null,
    canCopy: true,
  };
};

const formatAddressEntrancePassword = (password: string | undefined) => {
  return {
    id: uuidv4(),
    title: '공동현관 비밀번호',
    text: password,
    content: () => {
      return (
        <Font fontType="body1_medium" color="fill/dark">
          {password || '-'}
        </Font>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

const formatSubstitutedInfo = ({
  is_auto_confirmed,
  is_substituted_pharmacy,
  is_substituted_patient,
}: {
  is_auto_confirmed?: boolean | null;
  is_substituted_pharmacy?: boolean | null;
  is_substituted_patient?: boolean | null;
}) => {
  return {
    id: uuidv4(),
    title: '대체조제',
    text: '',
    content: () => {
      return (
        <div
          style={{
            display: 'flex',
            gap: '20px',
          }}
        >
          <Font
            fontType="body1_medium"
            color={
              is_substituted_patient === false
                ? 'state/distructive'
                : 'fill/dark'
            }
          >
            {`환자 : ${convertSubstitutedPatientKoreanStatus(
              is_substituted_patient,
            )} `}
          </Font>
          <Font
            fontType="body1_medium"
            color={
              is_substituted_patient === false
                ? 'state/distructive'
                : 'fill/dark'
            }
          >
            {`약사 : ${
              is_auto_confirmed
                ? convertSubstitutedPharmacyKoreanStatus(is_substituted_patient)
                : convertSubstitutedPharmacyKoreanStatus(
                    is_substituted_pharmacy,
                  )
            } `}
          </Font>
        </div>
      );
    },
    iconURL: null,
    canCopy: false,
  };
};

export const getReservationInformationList = ({
  item,
  status,
}: {
  item: TelepharmacyItemType;
  status: PageStatus;
}): IReservationInformationItem[] => {
  const proofUserTypeText = getProofUserType({ item });
  const patientName = getPatientName({ item });
  const reservationTime = getReservationTime({ item });
  const { deliveryType } = getDeliveryMethod({ item });

  const isNeedAddressInfo = deliveryType === '퀵' || deliveryType === '택배';

  let paymentException: string;
  switch (item.payment_status) {
    case COMMON_CODE.PAYMENT_STATUS.FAILED:
      paymentException = '결제실패';
      break;
    case COMMON_CODE.PAYMENT_STATUS.CANCELED:
      paymentException = '결제취소';
      break;
    default:
      paymentException = '';
      break;
  }

  switch (status) {
    case 'requested': {
      return [
        formatPickupMethodInfo(deliveryType),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        ...(isNeedAddressInfo
          ? [
              formatAddressStringInfo(item.address_string),
              formatAddressEntrancePassword(item.address_entrance_password),
              formatReservationTimeInfo(reservationTime),
            ]
          : [formatReservationTimeInfo(reservationTime)]),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0]?.symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
    }
    case 'accepted': {
      return [
        formatPickupMethodInfo(deliveryType),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        ...(isNeedAddressInfo
          ? [
              formatAddressStringInfo(item.address_string),
              formatAddressEntrancePassword(item.address_entrance_password),
              formatReservationTimeInfo(reservationTime),
            ]
          : [formatReservationTimeInfo(reservationTime)]),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0]?.symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
    }
    case 'confirmed': {
      return [
        formatPickupMethodInfo(deliveryType),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        ...(isNeedAddressInfo
          ? [
              formatAddressStringInfo(item.address_string),
              formatAddressEntrancePassword(item.address_entrance_password),
              formatReservationTimeInfo(reservationTime),
            ]
          : [formatReservationTimeInfo(reservationTime)]),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0].symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
    }
    case 'ongoing_quick': {
      return [
        formatPickupMethodInfo(deliveryType),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        formatAddressStringInfo(item.address_string),
        formatAddressEntrancePassword(item.address_entrance_password),
        formatReservationTimeInfo(reservationTime),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0].symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
    }
    case 'pick_up':
      return [
        formatPickupMethodInfo(deliveryType),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        formatReservationTimeInfo(reservationTime),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0].symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
    case 'ongoing_parcel':
      return [
        formatPickupMethodInfo(deliveryType),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        formatAddressStringInfo(item.address_string),
        formatAddressEntrancePassword(item.address_entrance_password),
        formatReservationTimeInfo(reservationTime),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0].symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
    default:
      return [
        formatPickupMethodInfo(deliveryType),
        formatIsDeliveryAvailableInfo(proofUserTypeText),
        formatSubstitutedInfo({
          is_auto_confirmed: item.auto_confirmed,
          is_substituted_patient: item.is_substituted_patient,
          is_substituted_pharmacy: item.is_substituted_pharmacy,
        }),
        formatPatientNameInfo(patientName),
        formatPhoneNumberInfo(
          phoneFormatter.prettyPhoneNumberFormatter(item.tm?.phone),
        ),
        formatReservationTimeInfo(reservationTime),
        formatSymptomInfo(
          item.tm?.symptom_keyword_history[0].symptom_keyword.name,
        ),
        formatReservationNumberInfo(item?.telemedicine_hash),
      ];
  }
};

export const getPaymentInformationList = ({
  item,
  status,
}: {
  item: TelepharmacyItemType;
  status: PageStatus;
}): IReservationInformationItem[] => {
  const { deliveryType } = getDeliveryMethod({ item });

  let paymentException: string;
  switch (item.payment_status) {
    case COMMON_CODE.PAYMENT_STATUS.FAILED:
      paymentException = '결제실패';
      break;
    case COMMON_CODE.PAYMENT_STATUS.CANCELED:
      paymentException = '결제취소';
      break;
    default:
      paymentException = '';
      break;
  }

  switch (status) {
    case 'requested': {
      return [];
    }
    case 'accepted': {
      return [
        formatPaymentStatusInfo(item.payment_status, paymentException),
        formatPendingPaymentAmountInfo(
          isNil(item.amount)
            ? ''
            : `${priceFormatter.commaFormatter(String(item.amount))}원`,
          paymentException,
        ),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
    }
    case 'confirmed': {
      return [
        formatPaymentAmountInfo(
          isNil(item.amount)
            ? ''
            : `${priceFormatter.commaFormatter(String(item.amount))}원`,
          paymentException,
        ),
        formatPaymentStatusInfo(item.payment_status, paymentException),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
    }
    case 'ongoing_quick': {
      return [
        formatPaymentAmountInfo(
          isNil(item.amount)
            ? ''
            : `${priceFormatter.commaFormatter(String(item.amount))}원`,
          paymentException,
        ),
        formatPaymentStatusInfo(item.payment_status, paymentException),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
    }
    case 'pick_up':
      return [
        formatPaymentAmountInfo(
          isNil(item.amount)
            ? ''
            : `${priceFormatter.commaFormatter(String(item.amount))}원`,
          paymentException,
        ),
        formatPaymentStatusInfo(item.payment_status, paymentException),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
    case 'ongoing_parcel':
      return [
        formatPaymentAmountInfo(
          isNil(item.amount)
            ? ''
            : `${priceFormatter.commaFormatter(String(item.amount))}원`,
          paymentException,
        ),
        formatPaymentStatusInfo(item.payment_status, paymentException),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
    case 'completed':
      return [
        formatPaymentStatusInfo(item.payment_status, paymentException),
        formatPaymentAmountInfo(
          isNil(item.amount)
            ? ''
            : `${priceFormatter.commaFormatter(String(item.amount))}원`,
          paymentException,
        ),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
    default:
      return [
        formatPickupMethodInfo(deliveryType),
        formatPaymentWayInfo(item.payment_type),
        formatPaymentDoneTimeInfo(item.payment_datetime),
      ];
  }
};
